<template>
  <svg id="Group_599" data-name="Group 599" xmlns="http://www.w3.org/2000/svg" width="15"
       viewBox="0 0 15 12.067">
    <rect id="Rectangle_498" data-name="Rectangle 498" width="2" height="7.556" transform="translate(0 4.511)"
          fill="#333" />
    <rect id="Rectangle_499" data-name="Rectangle 499" width="2.001" height="3.5" transform="translate(4.333 8.567)"
          fill="#333" />
    <rect id="Rectangle_500" data-name="Rectangle 500" width="2" height="10.882" transform="translate(8.666 1.185)"
          fill="#333" />
    <rect id="Rectangle_501" data-name="Rectangle 501" width="2.001" height="12.067" transform="translate(12.999)"
          fill="#333" />
  </svg>
</template>
