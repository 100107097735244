<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    viewBox="0 0 12 13">
    <path
      id="logout_FILL0_wght300_GRAD0_opsz48"
      d="M11.343,13.073h6.286l-1.75-1.8.589-.613,2.732,2.86-2.714,2.8-.589-.594,1.75-1.8h-6.3ZM13.164,7v.836H8.236a.218.218,0,0,0-.161.074A.235.235,0,0,0,8,8.077V18.923a.235.235,0,0,0,.071.167.218.218,0,0,0,.161.074h4.929V20H8.236a.979.979,0,0,1-.732-.316,1.059,1.059,0,0,1-.3-.761V8.077a1.059,1.059,0,0,1,.3-.761A.979.979,0,0,1,8.236,7Z"
      transform="translate(-7.2 -7)" fill="#333" />
  </svg>
</template>
